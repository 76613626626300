<template>
	<div>
		<div>
			<div class="form-search-box ">
				<a-form layout="inline">
					<a-form-item label="商户名">
						<a-input v-model="search.name" placeholder="请输入商户名称"></a-input>
					</a-form-item>

					<a-form-item label="手机号">
						<a-input v-model="search.mobile" placeholder="请输入手机号"></a-input>
					</a-form-item>
					
					<a-form-item>
						<a-button @click="searchAct" type="primary">查询</a-button>
						<a-button @click="cancelAct" class="ml10">取消</a-button>
					</a-form-item>
				</a-form>
			</div>
		</div>
		
		<div class="mt20">
			<div class=" flex space">
				<div>
					<a-button type="primary" icon="plus" @click="addyogaShopAct()">开通多门店</a-button>
				</div>
			</div>
		</div>

		<div class="mt20">
			<div class="wxb-table-white">
				<a-table rowKey="shop_id" :columns="columns" :pagination="pagination" @change="handleTableChange" :data-source="datas"
				 >
					
					<div slot="app" class="ft14 ftw500 cl-main" slot-scope="app,record">
						{{app ? app.name : '-' }}
					</div>
				 
					<div slot="relation" class="ft14 ftw500 cl-main" slot-scope="relation,record">
						{{relation.account.mobile}}{{relation.account.username ? '/'+relation.account.username : ''}}
					</div>
					
					<div class="flex alcenter center miniapp-table-logo" slot="auth" slot-scope="text,record">
						<img v-if="record.wx_face != null" :src="record.wx_face"/>
						<img v-else src="../../assets/image/logo48@2x.png"/>
						
						<div class="ml10">
							<div class="ft14  cl-main">小程序ID:{{record.wx_app_id ? record.wx_app_id : '暂无'}}</div>
							<div class="ft14 mt3 cl-notice">小程序名称:{{record.wx_name ? record.wx_name : '暂无'}}</div>
							<div class="ft14 mt3 cl-notice">运营单位:{{record.wx_principal_name ? record.wx_principal_name : '暂无'}}</div>
						</div>
					</div>
					
					<div class="flex alcenter center kefu-table-logo" slot="kefu" slot-scope="kefu,record">
						<div v-if="kefu != null" class="flex alcenter">
							<img v-if="kefu.qrcode != null" :src="kefu.qrcode"/>
							<img v-else src="../../assets/image/kefu.png"/>
							<div class="ml10">
								<div class="ft14  cl-main">客服名称:{{kefu.name ? kefu.name : '暂无'}}</div>
								<div class="ft14 mt3 cl-notice">客服手机号:{{kefu.mobile ? kefu.mobile : '暂无'}}</div>
							</div>
						</div>
						
						<div v-else> -</div>
					</div>

					<template slot="action" slot-scope="text,record">
						<div class="flex center">
							<a-dropdown placement="bottomRight">
								<span class="more-act">
									<i class="iconfont iconmore_gray"></i>
								</span>
								<a-menu slot="overlay">
									<a-menu-item>
										<a class="menu-act" href="javascript:;" @click="editbeautiful(record)">
											<i class="iconfont ft14 iconedit"></i>
											<span class="ml10">编辑</span>
										</a>
									</a-menu-item>
								</a-menu>
				 			</a-dropdown>
						</div>
					</template>
				</a-table>
			</div>
		</div>
			<div v-if="editShopVisible">
				<edit-shop :visible="editShopVisible" :account_id="account_id" @cancel="cancelEditShop"></edit-shop>
			</div>
			<div v-if="addyogaShopVisible">
				<addyoga-shop :visible="addyogaShopVisible" @cancel="canceladdyogaShop"></addyoga-shop>
			</div>
			
		
	</div>
</template>

<script>
	import {
		listMixin
	} from '../../common/mixin/list.js';
	import editShop from './components/edit/modal/editShop.vue';
	import addyogaShop from './components/edit/modal/addyogaShop.vue';
	export default {
		mixins: [listMixin],
		components:{
			editShop,
			addyogaShop,
		},
		data() {
			return {
				loading: false,
				editShopVisible:false,
				addyogaShopVisible:false,
				pagination: {
					current: 1,
					pageSize: 50, //每页中显示10条数据
					total: 0,
				},
				search: {
					name: '',
					mobile: "",
					app_id:0,
				},
				shop_id:0,
				customer_service_id:0,
				app_list:[],
				columns: [
					{title: 'ID编号',dataIndex: 'shop_id',align: 'center',ellipsis: true},
					{title: '小程序',key: 'auth',align: 'center',width:400,scopedSlots: {customRender: 'auth'}},
					{title: '商铺名称',dataIndex: 'name',align: 'center',ellipsis: true},
					{title: '门店可开通数量',dataIndex: 'store_num',align: 'center',width:250,scopedSlots: {customRender: 'store_num'}},
					{title: '有效期',dataIndex: 'expire_time_format',align: 'center',ellipsis: true},
					{title: '操作',key: 'action',align: 'center',scopedSlots: {customRender: 'action'}},
					
				],
				datas: [],
			}
		},
		methods: {
			
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('platform/admin/getYogaShopList',{
					name:this.search.name,
					mobile:this.search.mobile,
					is_multi:1,
					limit:this.pagination.pageSize,
					page:this.pagination.current,
				}).then(res=>{
					this.datas=res.list;
					this.pagination.total=res.total;
					this.app_list=res.app_list;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			
			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
			
			//编辑
			editbeautiful(record){
				this.account_id=record.account_id;
				this.editShopVisible=true;
			},	
			cancelEditShop(){
				this.editShopVisible=false;
			},

			//添加账号
			addyogaShopAct(){
				this.addyogaShopVisible=true;
			},
			canceladdyogaShop(){
				this.addyogaShopVisible=false;
			},			
		}
	}
</script>

<style>
	.miniapp-table-logo img{
		width: 80px;
		height: 80px;
		border-radius: 40px;
		box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.04);
		border: 2px solid #FFFFFF;
	}
	
	.kefu-table-logo img{
		width: 50px;
		height: 50px;
		border-radius: 25px;
	}
</style>
